import { LinkOff, Delete } from "@mui/icons-material";
import { Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormActions, SectionTitle } from "../../../../components/Form";
import { UpdateOnlyIcon } from "../../../../icons/ButtonIcons";
import AppDialog from "../../../../components/AppDialog";
import SearchFluid from "../../../master-data/fluids/SearchFluid";
import InsiteMachineSearch from "../../../master-data/unit-models/InsiteMachineSearch";
import SearchCustomer from "../../manage/search/SearchCustomer";
import useFetch from "../../../../hooks/useFetch";
import { toDateTimeFormat1, toLocalDayDate3, } from "../../../../utils";
import { useWindowPopups } from "../../../../components/useWindowPopups";
import { TRAY_APIS } from "../../../../common/apis";
import { useUser } from "../../../../rootContext";

export const RegistrationActions = {
  LOAD_DATA: "load-data",
  SELECT_FLUID: "select-fluid",
  RESET_FIELDS: "reset-reading",
  UPDATE_UNIT_MAKE_MODEL: "update-unit-make-model",
};

export function RegistrationFormReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === RegistrationActions.LOAD_DATA) {
    return { ...action.payload, _key: Date.now() };
  }
  if (action.type === RegistrationActions.SELECT_FLUID) {
    return {
      ...state,
      FluidName: action.payload.FluidName,
      FluidId: action.payload.FluidId,
      FluidTypeId: action.payload.FluidTypeId || 1,
      _key: Date.now()
    };
  }
  if (action.type === RegistrationActions.RESET_FIELDS) {
    return { ...state, ...action.payload, _key: Date.now() };
  }

  return state;
}

export const useFormSections = ({ onCustomerChanged, data, dispatch, componentData, CanRegister, setDataLists, decoupleHandler, checkFlag, setCheckFlag, dataLists }) => {
  const { get, post } = useFetch();
  const user = useUser();

  const [openCustomer, setOpenCustomer] = useState(false);
  const [openFluid, setOpenFluid] = useState(false);
  const [openInsite, setOpenInsite] = useState(false);

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  function sampleRowSelectedHandler(rowData) {
    dispatch({
      type: RegistrationActions.SELECT_FLUID,
      payload: {
        FluidId: rowData.FluidId,
        FluidName: rowData.FluidFormatted,
        FluidTypeId: rowData.FluidTypeId || 1,
      },
    });
    setOpenFluid(false);
  }

  const handleSelectCustomer = async (rowData) => {
    if (rowData?.CustomerId > 0) {
      const sitesResp = await get(`/masterData/sites?custId=${rowData?.CustomerId}`);

      setDataLists({
        ...dataLists,
        Sites: sitesResp,
      })
      dispatch({
        type: RegistrationActions.LOAD_DATA,
        payload: {
          ...data,
          CustomerId: rowData.CustomerId,
          Customer: rowData.Customer,
          CustomerNew: rowData.Customer,
        },
      });
      setOpenCustomer(false);
    }
  }

  const handleSelectUnit = async (rowData) => {
    if (rowData?.UnitId > 0) {
      const unitsResp = await get(`/masterData/units?siteId=${rowData?.SiteId}`);

      setDataLists({
        ...dataLists,
        Units: unitsResp,
        Components: [],
      })
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          SiteId: rowData?.SiteId,
          UnitId: rowData?.UnitId,
          ComponentId: null,
        },
      });
    }
  }

  const handleSelectComponent = async (rowData) => {
    if (rowData?.ComponentId > 0) {
      const compResp = await get(`/masterData/components?unitId=${rowData?.UnitId}`);

      setDataLists({
        ...dataLists,
        Components: compResp,
      })
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          ComponentId: rowData?.ComponentId,
        },
      });
    }
  }

  const handleExistingButton = (pLoad) => {
    dispatch({
      type: RegistrationActions.RESET_FIELDS,
      payload: pLoad,
    });
  }

  const handleSaveButton = async (name, pLoad) => {

    if (name === "UR_UNIT_MAKE") {
      await post(`${TRAY_APIS.UR_UNIT_MAKE}`, {
        UnitId: data?.UnitId,
        UnitMakeId: data?.UnitMakeId,
        Make: data?.UnitMakeNew,
        Model: data?.UnitModelNew,
      });
      setCheckFlag({ ...checkFlag, UR_UNIT_MAKE: true })
    }

    if (name === "UR_UNIT_SERIAL") {
      await post(`${TRAY_APIS.UR_UNIT_SERIAL}`, {
        UnitId: data?.UnitId,
        Serial: data?.SerialNew,
        UserId: user?.UserId,
        Screen: "Edit Registration"
      });
      setCheckFlag({ ...checkFlag, UR_UNIT_SERIAL: true })
    }

    if (name === "UR_COMPONENT_LOCATION") {
      await post(`${TRAY_APIS.UR_COMPONENT_LOCATION}`, {
        ComponentId: data?.ComponentId,
        Location: data?.ComponentLocationId,
      });
      setCheckFlag({ ...checkFlag, UR_COMPONENT_LOCATION: true })
    }

    if (name === "UR_COMPONENT_CAPACITY") {
      setCheckFlag({ ...checkFlag, UR_COMPONENT_CAPACITY: true })
      await post(`${TRAY_APIS.UR_COMPONENT_CAPACITY}`, {
        ComponentId: data?.ComponentId,
        Capacity: parseInt(data?.CapacityNew),
      });
    }

    if (name === "UR_COMPONENT_MAKE") {
      await post(`${TRAY_APIS.UR_COMPONENT_MAKE}`, {
        ComponentId: data?.ComponentId,
        ComponentMakeId: data?.ComponentMakeId,
        Make: data?.ComponentMakeNew,
        Model: data?.ComponentModelNew,
        UserId: user?.UserId,
        Screen: "Edit Registration"
      });
      setCheckFlag({ ...checkFlag, UR_COMPONENT_MAKE: true })
    }

    if (name === "UR_COMPONENT_SERIAL") {
      await post(`${TRAY_APIS.UR_COMPONENT_SERIAL}`, {
        ComponentId: data?.ComponentId,
        Serial: data?.ComponentSerialNew,
        UserId: user?.UserId,
        Screen: "Edit Registration"
      });
      setCheckFlag({ ...checkFlag, UR_COMPONENT_SERIAL: true })
    }
  }

  function eventCb(event) {
    if (event.storageArea != localStorage) return;
    const unitResp = JSON.parse(localStorage.getItem("edit-unit-resp"));
    if (unitResp?.UnitId > 0) {
      handleSelectUnit(unitResp)
      localStorage.removeItem("edit-unit-resp")
    }

    const compResp = JSON.parse(localStorage.getItem("edit-component-resp"));
    if (compResp?.ComponentId > 0) {
      handleSelectComponent(compResp)
      localStorage.removeItem("edit-component-resp")
    }
  }

  const handleCloseButton = () => {
    localStorage.setItem("isCloseWindow", true);
  }

  useEffect(function () {
    window.addEventListener("storage", eventCb);
    window.addEventListener("beforeunload", handleCloseButton);
    return function () {
      window.removeEventListener("storage", eventCb);
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });
  console.log("ER-", data)
  return useMemo(
    () => {
      return [
        {
          fields: [
            {
              xsCol: 12,
              hidden: !(data.SampleNumber > 0),
              sx: { paddingTop: 0 },
              component: function () {
                return (
                  <Typography
                    sx={{ color: "green" }}
                  >{`Registration ${data.RegistrationId} has been registered to sample ${data?.SampleNumber} on ${toLocalDayDate3(data?.Registered)} `}</Typography>
                );
              },
            },
            {
              xsCol: 12,
              hidden: !(data.RequiresAttention && data.Registered === null),
              sx: { paddingTop: 0 },
              component: function () {
                return (
                  <Typography
                    sx={{ color: "red" }}
                  >{`Registration ${data?.RegistrationId} has not been registered and REQUIRES ATTENTION. Please FIX ON THIS SCREEN`}</Typography>
                );
              },
            },
            {
              xsCol: 12,
              sx: { paddingTop: 0 },
              component: function () {
                return (
                  <Typography
                    sx={{ color: "purple" }}
                  >{`If anything is UNKNOWN. e.g. Meter Reading, then make the value EMPTY and NOT ZERO; zero means that the value is known and brand new`}</Typography>
                );
              },
            },
            {
              xsCol: 12,
              component: function () {
                return <SectionTitle title="Sample Information" />;
              },
            },
            {
              name: "SIF",
              label: "Registration",
              xsCol: 3,
              readOnly: true,
            },
            {
              name: "Sampled",
              label: "Date Sampled",
              xsCol: 3,
              type: "datetime",
            },
            {
              name: "Product",
              label: "Product",
              sx: {
                "& .MuiInputBase-input": {
                  backgroundColor: data?.ProductColour,
                  color: "white",
                },
              },
              xsCol: 3,
              readOnly: true,
            },
            (data?.SampleNumber > 0) && {
              xsCol: 3,
              group: [{
                name: "SampleNumber",
                label: "Sample Number",
                readOnly: true,
              },
              {
                alignSelf: "flex-end",
                component: function () {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        onClick={decoupleHandler}
                        hidden={(CanRegister !== true)}
                      >
                        Decouple Sample
                      </Button>
                    </>
                  )
                },
              },],
            },
            {
              xsCol: 12,
              component: function () {
                return <SectionTitle title="Customer Information" />;
              },
            },
            {
              name: "Customer",
              label: data?.CustomerId > 0 ? "Customer" : "Customer - Search Customer",
              labelColor: (data?.CustomerId > 0 || (data?.CustomerId !== undefined && data?.CustomerId !== null)) ? "" : "red",
              // label2: data?.CustomerId > 0 ? "✔" : "",
              label2: data?.CustomerId > 0 ? "✔" : data?.CustomerNew,
              label2Color: data?.CustomerId > 0 ? "green" : "",
              xsCol: 3,
              readOnly: true,
            },
            {
              name: "SiteId",
              label: data.SiteId > 0 ? "Site" : "Site - search or select",
              labelColor: (data?.SiteId > 0 || (data?.SiteId !== undefined && data?.SiteId !== null)) ? "" : "red",
              // label2: data?.SiteId > 0 ? "✔" : "",
              label2: data?.SiteId > 0 ? "✔" : data?.SiteNew,
              label2Color: data?.SiteId > 0 ? "green" : "",
              xsCol: 3,
              type: "dropdown",
              required: true,
              optionConfigs: {
                name: "Sites",
                textProp: "Site",
                valProp: "SiteId",
              },
            },
            {
              name: "UnitId",
              label: data.UnitId > 0 ? "Unit" : "Unit - search or select",
              labelColor: (data?.UnitId > 0 || (data?.UnitId !== undefined && data?.UnitId !== null)) ? "" : "red",
              // label2: data?.UnitId > 0 ? "✔" : "",
              // label2Color: data?.UnitId > 0 ? "green" : "",
              label2: data?.UnitNameOriginal?.length > 0 ? data?.UnitNameOriginal : data?.UnitNameNew,
              xsCol: 3,
              type: "dropdown",
              required: true,
              optionConfigs: {
                name: "Units",
                textProp: "Unit",
                valProp: "UnitId",
              },
            },
            {
              alignSelf: "flex-end",
              xsCol: 3,
              group: [
                {
                  alignSelf: "flex-end",
                  component: function ({ data }) {
                    return (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => setOpenCustomer(true)}
                        >
                          Search Customer
                        </Button>
                        <AppDialog
                          open={openCustomer}
                          title={"Customer Search"}
                          maxWidth="xl"
                          handleClose={() => setOpenCustomer(false)}
                        >
                          <SearchCustomer
                            actions={[
                              {
                                action: handleSelectCustomer,
                                label: "Select",
                              },
                            ]}
                            cancel={() => setOpenCustomer(true)}
                            selectedUnit={data}
                          />
                        </AppDialog>
                      </>
                    );
                  },
                },
                {
                  alignSelf: "flex-end",
                  component: function ({ data }) {
                    return (
                      <>
                        <Button
                          disabled={!(data?.CustomerId > 0)}
                          onClick={() => { HandleNewWindow("edit-unit") }}
                        >
                          Search Unit
                        </Button>
                      </>
                    );
                  },
                },
                {
                  alignSelf: "flex-end",
                  component: function ({ data }) {
                    return (
                      <>
                        <Button
                          variant="outlined"
                          disabled={!(data?.UnitId > 0)}
                          onClick={() => setOpenInsite(true)}
                        >
                          Search Insite
                        </Button>
                        <AppDialog
                          open={openInsite}
                          title="Insite Machine Search"
                          maxWidth="lg"
                          handleClose={() => setOpenInsite(false)}
                        >
                          <InsiteMachineSearch userData={data} />
                        </AppDialog>
                      </>
                    );
                  },
                },
              ],
            },

            {
              xsCol: 12,
              group: [
                {
                  xsCol: 12,
                  component: function () {
                    return <SectionTitle title="Machine Information" />;
                  },
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "UnitMakeNew",
                      label: "Make",
                      label2: checkFlag?.UR_UNIT_MAKE ? "✔" : data?.UnitMakeOriginal,
                      flex: 1,
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_UNIT_MAKE}
                              onClick={() => handleSaveButton("UR_UNIT_MAKE", { UnitMakeNew: data?.UnitMakeNew, UnitModelNew: data?.UnitModelNew })}>Save </Button>
                          </>
                        );
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_UNIT_MAKE}
                              onClick={() => { handleExistingButton({ UnitMakeNew: data?.UnitMakeOriginal, UnitModelNew: data?.UnitModelOriginal }); setCheckFlag({ ...checkFlag, UR_UNIT_MAKE: true }) }}>Existing </Button>
                          </>
                        );
                      },
                    },
                  ]
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "UnitModelNew",
                      label: "Model",
                      label2: data?.UnitModelOriginal === data?.UnitModelNew ? "✔" : data?.UnitModelOriginal,
                      flex: 1,
                    },
                  ],
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "SerialNew",
                      label: "Serial",
                      label2: checkFlag?.UR_UNIT_SERIAL ? "✔" : data?.SerialOriginal,
                      flex: 1,
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_UNIT_SERIAL}
                              onClick={() => handleSaveButton("UR_UNIT_SERIAL", { SerialNew: data?.SerialNew })} >Save </Button>
                          </>
                        );
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_UNIT_SERIAL}
                              onClick={() => { handleExistingButton({ SerialNew: data?.SerialOriginal, }); setCheckFlag({ ...checkFlag, UR_UNIT_SERIAL: true }) }}
                            >Existing </Button>
                          </>
                        );
                      },
                    },
                  ],
                },
              ],
            },

            {
              xsCol: 12,
              group: [
                {
                  label: data.ComponentId > 0 ? "Component" : "Component - search or select",
                  labelColor: data?.ComponentId > 0 ? "" : (data?.ComponentId !== undefined && data?.ComponentId !== null) ? "" : "red",
                  // label2: data?.ComponentId > 0 ? "✔" : "",
                  label2: data?.ComponentId > 0 ? "✔" : data?.ComponentNameNew,
                  label2Color: data?.ComponentId > 0 ? "green" : "",
                  name: "ComponentId",
                  xsCol: 3,
                  type: "dropdown",
                  required: true,
                  optionConfigs: {
                    name: "Components",
                    textProp: "Component",
                    valProp: "ComponentId",
                  },
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "ComponentLocationNew",
                      label: "Location",
                      label2: checkFlag?.UR_COMPONENT_LOCATION ? "✔" : data?.ComponentLocationOriginal,
                      flex: 1,
                      type: "dropdown",
                      optionConfigs: {
                        name: "ComponentLocations",
                        textProp: "Location",
                        valProp: "Location",
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_LOCATION}
                              onClick={() => handleSaveButton("UR_COMPONENT_LOCATION", { UnitMakeNew: data?.UnitMakeOriginal })} >Save </Button>
                          </>
                        );
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_LOCATION}
                              onClick={() => { handleExistingButton({ ComponentLocationNew: data?.ComponentLocationOriginal, }); setCheckFlag({ ...checkFlag, UR_COMPONENT_LOCATION: true }) }}
                            >Existing </Button>
                          </>
                        );
                      },
                    },
                  ],
                },
                {
                  xs: 3,
                  alignSelf: "flex-end",
                  component: function ({ data }) {
                    return (
                      <>
                        <Button
                          variant="outlined"
                          disabled={!(data?.UnitId > 0)}
                          onClick={() => { HandleNewWindow("edit-component") }}  >
                          Component
                        </Button>
                      </>
                    );
                  },
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "ComponentMakeNew",
                      label: "Comp Make",
                      label2: checkFlag?.UR_COMPONENT_MAKE ? "✔" : data?.ComponentMakeOriginal,
                      flex: 1,
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_MAKE}
                              onClick={() => handleSaveButton("UR_COMPONENT_MAKE", { UnitMakeNew: data?.UnitMakeOriginal })} >Save </Button>
                          </>
                        );
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_MAKE}
                              onClick={() => { handleExistingButton({ ComponentMakeNew: data?.ComponentMakeOriginal, ComponentModelNew: data?.ComponentModelOriginal, }); setCheckFlag({ ...checkFlag, UR_COMPONENT_MAKE: true }) }}
                            >Existing </Button>
                          </>
                        );
                      },
                    },
                  ],
                },
                {
                  name: "ComponentModelNew",
                  label: "Comp Model",
                  label2: data?.ComponentModelOriginal === data?.ComponentModelNew ? "✔" : data?.ComponentModelOriginal,
                  xsCol: 3,
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "ComponentSerialNew",
                      label: "Comp Serial",
                      label2: checkFlag?.UR_COMPONENT_SERIAL ? "✔" : data?.ComponentSerialOriginal,
                      flex: 1,
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_SERIAL}
                              onClick={() => handleSaveButton("UR_COMPONENT_SERIAL", { UnitMakeNew: data?.UnitMakeOriginal })} >Save </Button>
                          </>
                        );
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_SERIAL}
                              onClick={() => { handleExistingButton({ ComponentSerialNew: data?.ComponentSerialOriginal, }); setCheckFlag({ ...checkFlag, UR_COMPONENT_SERIAL: true }) }}
                            >Existing </Button>
                          </>
                        );
                      },
                    },
                  ],
                },
              ],
            },
            {
              name: "FluidTypeId",
              label: "Fluid Type",
              type: "dropdown",
              value: data.FluidName,
              xsCol: 3,
              disabled: true,
              optionConfigs: {
                name: "FluidTypes",
                textProp: "FluidType",
                valProp: "FluidTypeId",
              },
            },
            {
              name: "FluidName",
              label: "Fluid",
              label2: data?.FluidId > 0 ? "✔" : "",
              label2Color: data?.FluidId > 0 ? "green" : "",
              flex: 1,
              xsCol: 3,
              readOnly: true,
              sx: {
                [`.MuiInputBase-root`]: {
                  backgroundColor: data?.FluidId === null && "red",
                  color: data?.FluidId === null && "white",
                },
              },
            },
            {
              xs: 4,
              alignSelf: "flex-end",
              component: function ({ data }) {
                return (
                  <>
                    <Button variant="outlined" onClick={() => setOpenFluid(true)}>
                      Search Fluid
                    </Button>
                    <AppDialog
                      open={openFluid}
                      title="Fluid Search"
                      maxWidth="lg"
                      handleClose={() => setOpenFluid(false)}
                    >
                      <SearchFluid
                        rowSelected={sampleRowSelectedHandler}
                        propsFluidData={data}
                      />
                    </AppDialog>
                  </>
                );
              },
            },
            {
              xs: 3,
              alignSelf: "flex-end",
              component: function ({ data, dispatch }) {
                return (
                  <Tooltip
                    title={`Use '${data.ComponentMakeOriginal || ""}' and '${data.ComponentModelOriginal || ""
                      }' as Make and Model respectively.`}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        dispatch({
                          type: RegistrationActions.RESET_FIELDS,
                          payload: {
                            ComponentMakeNew: data.ComponentMakeOriginal,
                            ComponentModelNew: data.ComponentModelOriginal,
                          },
                        })
                      }
                    >
                      Use Default
                    </Button>
                  </Tooltip>
                );
              },
            },
            {
              xs: 3,
              alignSelf: "flex-end",
              component: function ({ data }) {
                return (
                  data?.FluidId === null && <Typography color="error">Fluid needs to be created - click Search to add or select</Typography>
                );
              },
            },
            {
              xsCol: 12,
              component: function () {
                return <SectionTitle title="Other Information" />;
              },
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "UnitMeter",
                  label: "Meter Reading",
                  label2: componentData?.LastMeterReading || "",
                  xsCol: 3,
                  type: "number",
                },
                {
                  name: "ComponentMeter",
                  label: "Comp. Reading",
                  label2: componentData?.LastComponentHours || "",
                  xsCol: 3,
                  type: "number",
                },
                {
                  name: "FluidMeter",
                  label: "Fluid Hours",
                  label2: componentData?.LastFluidHours || "",
                  xsCol: 3,
                  type: "number",
                  max: 999999999,
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "Topup",
                      label: "Topup",
                      xsCol: 6,
                      type: "number",
                      max: 999999999,
                    },
                    {
                      name: "FilterChanged",
                      label: "Filter Changed",
                      xsCol: 6,
                      type: "switch",
                    },
                  ],
                },

              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "JobNumber",
                  label: "Job Number",
                  xsCol: 3,
                },
                {
                  name: "ServiceTypeId",
                  label: "Service",
                  xsCol: 3,
                  type: "dropdown",
                  optionConfigs: {
                    name: "ServiceTypes",
                    textProp: "ServiceType",
                    valProp: "ServiceTypeId",
                  },
                },
                {
                  name: "MeasureId",
                  label: "Measure",
                  xsCol: 3,
                  type: "dropdown",
                  disabled: true,
                  optionConfigs: {
                    name: "Measures",
                    textProp: "MeasureLong",
                    valProp: "MeasureId",
                  },
                },
                {
                  xsCol: 3,
                  group: [
                    {
                      name: "CapacityNew",
                      label: "Capacity",
                      label2: checkFlag?.UR_COMPONENT_CAPACITY && data?.CapacityOriginal >= 0 ? "✔" : (data?.CapacityOriginal || "") + " litres",
                      flex: 1,
                      type: "number",
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_CAPACITY}
                              onClick={() => handleSaveButton("UR_COMPONENT_CAPACITY", { UnitMakeNew: data?.UnitMakeOriginal })}  >Save </Button>
                          </>
                        );
                      },
                    },
                    {
                      alignSelf: "flex-end",
                      component: function ({ data }) {
                        return (
                          <>
                            <Button variant="contained" color="purple" sx={{ width: "75px" }} disabled={checkFlag?.UR_COMPONENT_CAPACITY}
                              onClick={() => { handleExistingButton({ CapacityNew: data?.CapacityOriginal, }); setCheckFlag({ ...checkFlag, UR_COMPONENT_CAPACITY: true }) }}
                            >Existing </Button>
                          </>
                        );
                      },
                    },
                  ],
                },
              ],
            },

            {
              xsCol: 6,
              group: [
                {
                  name: "FluidChanged",
                  label: "Fluid Changed",
                  type: "switch",
                  xsCol: 3,
                },
                {
                  name: "FluidChangedAt",
                  label: "Changed At",
                  type: "number",
                  disabled: (data) => !data.FluidChanged,
                  xsCol: 3,
                },
                {
                  alignSelf: "flex-end",
                  component: function ({ data, dispatch }) {
                    return (
                      <Button
                        variant="contained" color="purple"
                        disabled={!data.FluidChanged}
                        onClick={() =>
                          dispatch({
                            type: RegistrationActions.RESET_FIELDS,
                            payload: {
                              FluidChangedAt: data.UnitMeter,
                            },
                          })
                        }
                      >
                        Set SMR
                      </Button>
                    );
                  },
                },
                {
                  alignSelf: "flex-end",
                  name: "FluidChangedAtDate",
                  type: "datetime",
                  disabled: (data) => !data.FluidChanged,
                  xsCol: 3,
                },
              ],
            },

            {
              xsCol: 6,
              group: [
                {
                  name: "UnitMeterChanged",
                  label: "Meter Changed",
                  type: "switch",
                  xsCol: 3,
                },
                {
                  name: "UnitMeterChangedAt",
                  label: "Changed At",
                  type: "number",
                  disabled: (data) => !data.UnitMeterChanged,
                  xsCol: 3,
                },
                {
                  alignSelf: "flex-end",
                  component: function ({ data, dispatch }) {
                    return (
                      <Button
                        variant="contained" color="purple"
                        disabled={!data.UnitMeterChanged}
                        onClick={() =>
                          dispatch({
                            type: RegistrationActions.RESET_FIELDS,
                            payload: {
                              UnitMeterChangedAt: data.UnitMeter,
                            },
                          })
                        }
                      >
                        Set SMR
                      </Button>
                    );
                  },
                },
                {
                  alignSelf: "flex-end",
                  name: "UnitMeterChangedAtDate",
                  type: "datetime",
                  disabled: (data) => !data.UnitMeterChanged,
                  xsCol: 3,
                },
              ],
            },
            {
              xsCol: 6,
              group: [
                {
                  name: "ComponentChanged",
                  label: "Comp. Changed",
                  type: "switch",
                  xsCol: 3,
                },
                {
                  name: "ComponentChangedAt",
                  label: "Changed At",
                  type: "number",
                  disabled: (data) => !data.ComponentChanged,
                  xsCol: 3,
                },
                {
                  alignSelf: "flex-end",
                  component: function ({ data, dispatch }) {
                    return (
                      <Button
                        variant="contained" color="purple"
                        disabled={!data.ComponentChanged}
                        onClick={() =>
                          dispatch({
                            type: RegistrationActions.RESET_FIELDS,
                            payload: {
                              ComponentChangedAt: data.UnitMeter,
                            },
                          })
                        }
                      >
                        Set SMR
                      </Button>
                    );
                  },
                },
                {
                  alignSelf: "flex-end",
                  name: "ComponentChangedAtDate",
                  type: "datetime",
                  disabled: (data) => !data.ComponentChanged,
                  xsCol: 3,
                },
              ],
            },
            {
              xsCol: 6,
              group: [
                {
                  name: "ComponentRepaired",
                  label: "Comp. Repaired",
                  type: "switch",
                  xsCol: 3,
                },
                {
                  name: "ComponentRepairedAt",
                  label: "Repaired At",
                  type: "number",
                  disabled: (data) => !data.ComponentRepaired,
                  xsCol: 3,
                },
                {
                  alignSelf: "flex-end",
                  component: function ({ data, dispatch }) {
                    return (
                      <Button
                        variant="contained" color="purple"
                        disabled={!data.ComponentRepaired}
                        onClick={() =>
                          dispatch({
                            type: RegistrationActions.RESET_FIELDS,
                            payload: {
                              ComponentRepairedAt: data.UnitMeter,
                            },
                          })
                        }
                      >
                        Set SMR
                      </Button>
                    );
                  },
                },
                {
                  name: "ComponentRepairedAtDate",
                  type: "datetime",
                  disabled: (data) => !data.ComponentRepaired,
                  xsCol: 3,
                },
              ],
            },
            {
              name: "Notes",
              label: "Notes",
              type: "textarea",
              xsCol: 8,
              rows: 4,
            },
            {
              // hidden: !(data.IsDeleted),
              sx: { mt: 2 },
              xsCol: 4,
              component: function ({ data }) {
                return (<>
                  {(data.IsDeleted === true)
                    ? <Typography color={"red"} >
                      Added: {(toDateTimeFormat1(data?.Added) || "") + " by " + (data?.AddedBy || "") + " " + (data?.AddedByUsername || "")} <br />
                      Modified: {(toDateTimeFormat1(data?.Modified) || "") + " by " + (data?.ModifiedBy || "")}  <br />
                      This registration was deleted
                    </Typography>
                    :
                    (data.SampleNumber > 0)
                      ? <Typography color={"purple"}>
                        Added: {toDateTimeFormat1(data?.Added) + " by " + data?.AddedBy + " " + data?.AddedByUsername} <br />
                        Sample received  {(toDateTimeFormat1(data?.Received) || "")} <br />
                        Sample approved {(toDateTimeFormat1(data?.Approved) || "")}
                      </Typography>
                      : <Typography color={"purple"} >
                        Added: {(toDateTimeFormat1(data?.Added) || "") + " by " + data?.AddedBy + " " + data?.AddedByUsername}
                      </Typography>}
                </>);
              },
            },
          ],
        },
      ]
    },
    [
      onCustomerChanged,
      openCustomer,
      openFluid,
      openInsite,
      data,
      checkFlag,
    ]
  );
};

export const useFormButtons = ({
  CanRegister,
  submitHandler,
  handleDeleteRegistration,
  handleRegistrationClick,
  handleRegistrationCard,
  sampleNo,
}) => [
    {
      hidden: !CanRegister,
      label: "Delete",
      color: "danger",
      onClick: handleDeleteRegistration,
      startIcon: <Delete />,
    },
    {
      hidden: !CanRegister,
      label: "View Card",
      variant: "outlined",
      onClick: handleRegistrationCard,
      startIcon: <LinkOff />,
    },
    {
      hidden: !CanRegister,
      label: "Update Only",
      onClick: submitHandler,
      startIcon: <UpdateOnlyIcon />,
    },
    {
      hidden: (!CanRegister),
      label: "Registration",
      onClick: handleRegistrationClick,
    },
  ]
