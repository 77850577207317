import { useTheme } from "@emotion/react";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "./../../../../components/Form";
import useFetch from "./../../../../hooks/useFetch";
import { CONTACTS_APIS } from "./../../../../common/apis";
import { toLocalDateTime } from "../../../../utils";
import NewDataTable from "../../../../components/NewDataTable";

export default function ContactExports({ contactId, searchContactRowData, treeData }) {

  const curTheme = useTheme();
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const [contactsExportData, setContactsExportData] = useState([]);

  const filteredExportData = useMemo(() => {
    if (data?.Filter) {
      return (contactsExportData || []).filter((x) => {
        // Ensure x.SampleNumber is a string before using includes()
        return String(x.SampleNumber)?.includes(data?.Filter);
      });
    } else {
      return contactsExportData;
    }
  }, [data, contactsExportData]);

  const options = {
    columns: GetColumns(),
    dataSource: (filteredExportData || [])?.map((x, i) => ({ ...x, keyProp: i, })),
    cellStylingHandler: "contactExports",

    sx: { height: "75dvh" },
    getRowId: (originalRow) => (originalRow?.keyProp || ""),

    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.ProductColour + "  !important"),
          color: (row?.original?.Primary !== null ? "white !important" : ""),
          py: 0,
          border: "0.5px solid #d0d0d0",
          height: "22px",
        } : cell.column.columnDef.accessorKey === "SampleNumber" ? {
          backgroundColor: (row?.original?.AlertColour + "  !important"),
          color: "white !important",
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : cell.column.columnDef.accessorKey === "IsSampleReport" ? {
          backgroundColor: (row?.original?.IsSampleReport === true ? "purple !important" : ""),
          color: (row?.original?.IsSampleReport === true ? "white !important" : ""),
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : cell.column.columnDef.accessorKey === "IsSMS" ? {
          backgroundColor: (row?.original?.IsSMS === true ? "purple !important" : ""),
          color: (row?.original?.IsSMS === true ? "white !important" : ""),
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : cell.column.columnDef.accessorKey === "BatchAdded" ? {
          backgroundColor: "green !important",
          color: "white !important",
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : {
          py: 0, height: "22px", borderLeft: "1px solid #d0d0d0"
        }
      }
    },
    muiTableBodyRowProps: ({ cell, column, row, table }) => {
      return {
        sx: { height: "22px", py: 0, borderLeft: "1px solid #d0d0d0" }
      }
    },
  };
  //call api

  const getContactsForExport = async (dataProps) => {
    const contactExportResponse = await post(
      `${CONTACTS_APIS.CONTACT_FOR_EXPORT}`, {
      ContactId: dataProps?.ContactId,
      ProductId: dataProps?.ProductId,
      SiteSetId: dataProps?.SiteSetId,
      ParentId: dataProps?.ParentId,
      CustomerId: dataProps?.CustomerId,
      SiteId: dataProps?.SiteId,
      UnitId: dataProps?.UnitId,
      ComponentId: dataProps?.ComponentId,
      SampleNumber: dataProps?.SampleNumber,
      ReportNumber: dataProps?.ReportNumber,
      LimitResults: data?.limitResults == false ? false : true,
    },
    );
    setContactsExportData(contactExportResponse)
  }

  useEffect(
    function () {
      (async function () {
        let formDatas = JSON.parse(localStorage.getItem("export-contacts-overview"));
        if (treeData !== undefined) {
          return getContactsForExport(treeData);
        }
        else if (formDatas !== null && formDatas !== undefined) {
          if (formDatas?.ContactId > 0 && formDatas?.PropId > 0) {
            const contactExportResponse = await get(`${CONTACTS_APIS?.CONTACT_EXPORTS}?contactId=${formDatas?.ContactId}&limitResults=${data?.limitResults === true ? true : false}`, { skipBlocker: true });
            const contactsExportDataWithProperties = contactExportResponse?.map(
              (item) => ({
                ...item,
                Username: formDatas?.UserName || searchContactRowData?.Username,
                DisplayName: formDatas?.DisplayName || searchContactRowData?.DisplayName,
              })
            );
            document.title = "Contact Exports - " + (formDatas?.UserName || searchContactRowData?.Username || "");
            setContactsExportData(contactsExportDataWithProperties);
          }
          else {
            return getContactsForExport(formDatas);
          }
        }
        else if (contactId > 0) {
          const contactExportResponse = await get(`${CONTACTS_APIS.CONTACT_EXPORTS}?contactId=${contactId ? contactId : 0}&limitResults=${data?.limitResults === true ? true : false}`, { skipBlocker: true });
          const contactsExportDataWithProperties = contactExportResponse?.map(
            (item) => ({
              ...item,
              Username: searchContactRowData?.Username,
              DisplayName: searchContactRowData?.DisplayName,
            })
          );
          document.title = "Contact Exports - " + (searchContactRowData?.Username || "");
          setContactsExportData(contactsExportDataWithProperties);
        }
      })();
    },
    [data?.limitResults]
  );

  const handleCloseButton = () => {
    localStorage.removeItem("export-contacts-overview");
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);

    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });

  return (
    <>
      <Grid container sx={{ minWidth: "95vw" }}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections()}
            data={data}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredExportData?.length}</b>
          </Typography>
          <NewDataTable {...options} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns() {
  return [
    {
      header: "Username",
      accessorKey: "Username",
      size: 200,
    },
    {
      header: "Name",
      accessorKey: "DisplayName",
      size: 150,
    },
    {
      header: "Added",
      accessorKey: "Added",
      Cell: ({ renderedCellValue }) => toLocalDateTime(renderedCellValue),
      size: 120,
    },
    {
      header: "Batch",
      accessorKey: "BatchAdded",
      Cell: ({ renderedCellValue }) => toLocalDateTime(renderedCellValue),
      size: 150,
    },
    {
      header: "Sample",
      enableSorting: false,
      accessorKey: "IsSampleReport",
      Cell: ({ renderedCellValue }) => (renderedCellValue === true ? "Yes" : "No"),
      size: 50,
    },
    {
      header: "SMS",
      accessorKey: "IsSMS",
      Cell: ({ renderedCellValue }) => (renderedCellValue === true ? "Yes" : "No"),
      size: 50,
    },
    {
      header: "SampleNumber",
      accessorKey: "SampleNumber",
      size: 120,
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 170,
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 170,
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 150,
    },

    {
      header: "Customer",
      accessorKey: "Customer",
      size: 160,
    },
    {
      header: "Product",
      accessorKey: "Product",
      size: 120,
    },
  ];
}

function GetFormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Filter", label: "Filter", xsCol: 3 },
        {
          name: "limitResults",
          label: "Show Only Last 3 Months",
          xsCol: 4,
          type: "switch",
        },
      ],
    },
  ];
}
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }

  return state;
}
const SearchFilterDefaults = {
  limitResults: true,
};
