// KOWA PROD KAL 08-Sep-2023

import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: "9d80085e-c305-4076-8a8a-8040a9f79bb2", // app registraion front-end web client
    authority:
      "https://login.microsoftonline.com/a6bd55f8-4d88-4103-904e-9287fc1eec88", // KLTD Dev tenant for authentication
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //   console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const loginRequest = {
  scopes: ["api://9d80085e-c305-4076-8a8a-8040a9f79bb2/api.scope"],
  //7b81739d-e2a9-4f16-9575-588b4ecdd498 this key is used for api authorization.
};

export const msai = {
  instrumentationKey: "2e8ad079-467f-4e26-8912-7a0c67ca6c3c" //"5afbfb76-07f0-4628-be12-93c0d831eaeb"//MS Azure App insights key
}